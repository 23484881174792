import React, { useState, useEffect } from 'react';

export default function EditableTextArea({ text, onTextChange }) {
    const [editing, setEditing] = useState(false);
    const [currentText, setCurrentText] = useState(text || '');

    useEffect(() => {
        setCurrentText(text || '');
    }, [text]);

    const handleChange = (e) => {
        setCurrentText(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            onTextChange(currentText);
            setEditing(false);
        }
    };

    const handleBlur = () => {
        onTextChange(currentText);
        setEditing(false);
    };

    if (!editing) {
        return (
            <div className="cursor-pointer w-full h-full inline-flex items-center max-w-screen-xl whitespace-pre-line" onDoubleClick={() => setEditing(true)}>
                {currentText}
            </div>
        );
    } else {
        return (
            <div className="w-full h-full inline-flex items-center max-w-screen-xl">
                <textarea
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="w-full"
                    autoFocus
                    value={currentText}
                    rows={4} // Adjust the number of visible rows if needed
                />
            </div>
        );
    }
}