import React from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import { useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useLocation } from 'react-router-dom';

export default function TopBar() {
  // TODO: Add logout functionality
    const [hover, setHover] = useState(false);
  const handleLogout = async () => {
    try {
      await signOut(auth);  // Signing out the user
      console.log('User logged out successfully');
      // Optionally, redirect the user or update the state
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  function HeaderView() {
    const location = useLocation();
    console.log(location.pathname);
    if(location.pathname === "/") {
      return <span>Dashboard</span>
    } else if(location.pathname === "/licenses") {
      return <span>Licenses</span>
    } else if(location.pathname === "/plugins") {
      return <span>Plugins</span>
    } else if(location.pathname === "/functions") {
      return <span>Functions</span>
    } else if(location.pathname === "/upload") {
      return <span>Upload</span>
    }
  }

  return (
    <div className="text-xl bg-white text-black py-2 justify-between flex px-4 align-center">
      <h1>{HeaderView()}</h1>
      <div className="profile flex align-center items-center" onMouseEnter={() => {setHover (true)}} onMouseLeave={() => {setHover(false)}}
      >
        <FaSignOutAlt onClick={handleLogout} className={`cursor-pointer ${hover ? "text-red-500" : ""}`} />
      </div>
    </div>
  );
}
