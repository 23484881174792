import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('red');

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    if (!file) {
      setMessage('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    axios.post('https://plugins.sw-dev.nl/upload.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      if (response.status === 200) {
        setMessage('File successfully uploaded!');
      } else {
        setMessage('File upload failed!');
      }
    })
    .catch(error => {
      setMessage('File upload failed!');
      console.error('There was an error uploading the file!', error);
    });
  };

  return (
    <div className="flex flex-col pt-64 items-center h-full bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4 ">File Upload</h2>
        <input 
          type="file" 
          onChange={onFileChange} 
          className="mb-4 p-2 border rounded w-full"
        />
        <button 
          onClick={onFileUpload}
          className="bg-saleswizard text-white p-2 rounded w-full hover:bg-saleswizardhover transition duration-200"
        >
          Upload
        </button>
        {message && (
          <p className="mt-4 text-center text-sm text-{color}-500">{message}</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
