import React, { useState, useEffect } from 'react';

export default function EditableText({ text, onTextChange }) {
    const [editing, setEditing] = useState(false);
    const [currentText, setCurrentText] = useState(text || '');

    useEffect(() => {
        setCurrentText(text || '');
    }, [text]);

    const handleChange = (e) => {
        setCurrentText(e.target.value);
        console.log(currentText);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onTextChange(currentText);
            setEditing(false);
        }
    };

    const handleBlur = () => {
        onTextChange(currentText);
        setEditing(false);
    };

    if (!editing) {
        return (
            <div className="cursor-pointer w-full h-full inline-flex items-center max-w-screen-xl truncate" onDoubleClick={() => setEditing(true)}>
                {currentText}
            </div>
        );
    } else {
        return (
            <div className="w-full h-full inline-flex items-center max-w-screen-xl truncate">
                <input
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="w-full"
                    autoFocus
                    type="text"
                    value={currentText}
                />
            </div>
        );
    }
}
